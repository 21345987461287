import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '@environment/environment';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { RexToastrInformationService } from './services/toastr/rex-toastr-information.service';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { LoggerModule } from 'ngx-logger';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({ 
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ], 
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatDialogModule,
        LoggerModule.forRoot({ level: environment.loggerLevel })
    ], 
    providers: [
        { provide: FIREBASE_OPTIONS, useValue: environment.firebaseKeys },
        {
            provide: 'RexToastrInformationService',
            useFactory: (snackBar: MatSnackBar) => {
                return new RexToastrInformationService(snackBar);
            },
            deps: [MatSnackBar]
        },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        provideHttpClient(withInterceptorsFromDi()),
        provideFirebaseApp(() => initializeApp(environment.firebaseKeys)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth())
    ]
})
export class AppModule { }
